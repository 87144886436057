import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "progress-bar",
    role: "progressbar",
    style: _normalizeStyle('width: ' + _ctx.percent + '%')
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}