import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","white-space":"nowrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_btn = _resolveComponent("bs-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _cache[2] || (_cache[2] = _createTextVNode(" ")),
    _createVNode(_component_bs_btn, {
      variant: "primary",
      small: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard()))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "bi bi-clipboard" }, null, -1)
      ])),
      _: 1
    })
  ]))
}