import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card-body row justify-content-start" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.announcements, (announcement, idx) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: idx,
        class: _normalizeClass(
        'card bg-' +
        announcement.background_color +
        ' text-' +
        announcement.text_color
      )
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "bi bi-info-square col col-auto" }, null, -1)),
          _createElementVNode("div", {
            class: "col align-self-stretch",
            innerHTML: announcement.message
          }, null, 8, _hoisted_2)
        ])
      ], 2)), [
        [_vShow, _ctx.announcements]
      ])
    }), 128))
  ]))
}