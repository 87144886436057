import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_ghost_editor = _resolveComponent("ghost-editor")!

  return (_ctx.ghost !== null)
    ? (_openBlock(), _createBlock(_component_ghost_editor, {
        key: 0,
        modelValue: _ctx.ghost,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ghost) = $event)),
        onSaved: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_bs_btn, {
            variant: "danger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "bi bi-x-square" }, null, -1),
              _createTextVNode(" Abort ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}