import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-item dropdown" }
const _hoisted_2 = {
  "data-bs-toggle": "dropdown",
  class: "nav-link dropdown-toggle",
  role: "button",
  "aria-expanded": "false"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _renderSlot(_ctx.$slots, "toggle", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['dropdown-menu', { 'dropdown-menu-end': _ctx.end }]),
      style: _normalizeStyle(_ctx.minWidth ? `min-width: ${_ctx.minWidth}` : '')
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ]))
}