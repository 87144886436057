import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-unstyled" }
const _hoisted_2 = { class: "badge bg-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_checkbox_multiple = _resolveComponent("bs-checkbox-multiple")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTags, (tag) => {
      return (_openBlock(), _createElementBlock("li", {
        key: tag.id
      }, [
        _createVNode(_component_bs_checkbox_multiple, {
          modelValue: _ctx.selectedTags,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTags) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.selectedTags)))
          ],
          value: tag
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(tag.name), 1),
            _createTextVNode(" " + _toDisplayString(tag.description), 1)
          ]),
          _: 2
        }, 1032, ["modelValue", "value"])
      ]))
    }), 128))
  ]))
}