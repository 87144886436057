import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-floating" }
const _hoisted_2 = ["id", "value", "type", "placeholder", "aria-label", "disabled", "aria-disabled", "required", "aria-required"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'input-group': true, disabled: _ctx.disabled })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        id: 'input--' + _ctx.$.uid,
        value: _ctx.modelValue,
        type: _ctx.type,
        class: "form-control",
        placeholder: _ctx.placeholder,
        "aria-label": _ctx.label,
        disabled: _ctx.disabled,
        "aria-disabled": _ctx.disabled,
        required: _ctx.required,
        "aria-required": _ctx.required,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 40, _hoisted_2),
      _createElementVNode("label", {
        for: 'input--' + _ctx.$.uid
      }, _toDisplayString(_ctx.label), 9, _hoisted_3)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}