import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_alert = _resolveComponent("bs-alert")!
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_floating_single_line_input = _resolveComponent("floating-single-line-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Profile", -1)),
    _withDirectives(_createVNode(_component_bs_alert, { variant: "danger" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.err.split('\n'), (line, i) => {
          return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(line), 1))
        }), 128))
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.err]
    ]),
    _createVNode(_component_floating_single_line_input, {
      modelValue: _ctx.wantedUsername,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wantedUsername) = $event)),
      label: "Username"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_bs_btn, {
          disabled: !_ctx.wantedUsername || _ctx.wantedUsername === _ctx.profileInfo.username,
          variant: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeUsername()))
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createElementVNode("span", { class: "bi bi-person-badge" }, null, -1),
            _createTextVNode(" Change Username ")
          ])),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_floating_single_line_input, {
      modelValue: _ctx.profileInfo.auth_token,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profileInfo.auth_token) = $event)),
      label: "Auth Token",
      type: "text",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_bs_btn, {
          variant: "primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.refreshToken()))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createElementVNode("span", { class: "bi bi-arrow-repeat" }, null, -1),
            _createTextVNode(" Regenerate ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_floating_single_line_input, {
      modelValue: _ctx.wantedEmail,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.wantedEmail) = $event)),
      label: "Email",
      type: "email",
      required: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_bs_btn, {
          disabled: _ctx.profileInfo.email === _ctx.wantedEmail,
          variant: "primary",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeEmail()))
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "bi bi-check" }, null, -1),
            _createTextVNode(" Change ")
          ])),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_floating_single_line_input, {
      modelValue: _ctx.wantedPassword,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.wantedPassword) = $event)),
      label: "Password",
      type: "password"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_bs_btn, {
          disabled: !_ctx.wantedPassword,
          variant: "primary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changePassword()))
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("span", { class: "bi bi-key" }, null, -1),
            _createTextVNode(" Change Password ")
          ])),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}