import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "list-group mt-1" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col mb-2 rounded border-secondary border" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col col-4" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col col-auto" }
const _hoisted_8 = { class: "row container justify-content-start" }
const _hoisted_9 = {
  key: 0,
  class: "col badge text-bg-info"
}
const _hoisted_10 = { class: "col text-secondary" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  key: 0,
  class: "row mt-1"
}
const _hoisted_13 = { class: "col col-auto" }
const _hoisted_14 = { class: "col col-8 justify-content-start" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = {
  key: 0,
  class: "row"
}
const _hoisted_17 = {
  key: 1,
  class: "row text-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_alert = _resolveComponent("bs-alert")!
  const _component_tag_filter_selector = _resolveComponent("tag-filter-selector")!
  const _component_clipboard_copyable = _resolveComponent("clipboard-copyable")!
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_tag_list = _resolveComponent("tag-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.profile.authenticated)
      ? (_openBlock(), _createBlock(_component_bs_alert, {
          key: 0,
          variant: "primary"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" To join a session, you need to register. ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_tag_filter_selector, {
      modelValue: _ctx.filterTags,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterTags) = $event)),
      "available-tags": _ctx.tags
    }, null, 8, ["modelValue", "available-tags"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSessions, (session) => {
        return (_openBlock(), _createElementBlock("div", {
          key: session.id,
          class: "list-group-item"
        }, [
          (session.time !== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "bi bi-calendar3" }, null, -1)),
                  _createTextVNode(" Scheduled event, starting " + _toDisplayString(_ctx.datetime(session.time.start)) + ", ending " + _toDisplayString(_ctx.datetime(session.time.end)) + ". ", 1),
                  _cache[3] || (_cache[3] = _createElementVNode("small", null, "All times are in your local time zone.", -1))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (session.private)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[4] || (_cache[4] = [
                          _createElementVNode("span", { class: "bi bi-eye-slash" }, null, -1),
                          _createTextVNode(" Private Session ")
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_10, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-regular fa-user" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(session.owner), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_clipboard_copyable, {
                      value: session.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("code", null, _toDisplayString(session.id), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  ])
                ])
              ]),
              (
                _ctx.profile.$state.username === session.owner ||
                _ctx.profile.$state.is_staff
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_bs_btn, {
                        variant: "danger",
                        small: "",
                        onClick: ($event: any) => (_ctx.deleteSession(session.id))
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "bi bi-trash" }, null, -1),
                          _createTextVNode(" Delete ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_bs_btn, {
                        class: "ms-1",
                        variant: "primary",
                        small: "",
                        onClick: ($event: any) => (_ctx.$router.push('/edit-session/' + session.id))
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("i", { class: "bi bi-pencil" }, null, -1),
                          _createTextVNode(" Edit ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                (session.tags && session.tags.length > 0)
                  ? (_openBlock(), _createBlock(_component_tag_list, {
                      key: 0,
                      tags: session.tags
                    }, null, 8, ["tags"]))
                  : _createCommentVNode("", true)
              ]),
              (session.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fa-regular fa-comment" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(session.description), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (session.players && session.players.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fa fa-people-group" }, null, -1)),
                    _createTextVNode(_toDisplayString(session.players.join(", ")) + ". ", 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}