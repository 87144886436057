import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-items-center px-8",
  style: {"padding":"0.6666em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bs_btn = _resolveComponent("bs-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/profile",
      class: "flex-row btn btn-info"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "bi bi-person" }, null, -1),
        _createTextVNode(" Profile ")
      ])),
      _: 1
    }),
    _createVNode(_component_bs_btn, {
      variant: "primary",
      onClick: _ctx.logoutClicked
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "bi bi-box-arrow-left" }, null, -1),
        _createTextVNode(" Logout ")
      ])),
      _: 1
    }, 8, ["onClick"])
  ]))
}