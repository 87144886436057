import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false",
  class: "btn btn-primary dropdown-toggle"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tags_selector = _resolveComponent("tags-selector")!
  const _directive_bs_tooltip = _resolveDirective("bs-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, [
      _withDirectives(_createElementVNode("span", null, _cache[2] || (_cache[2] = [
        _createElementVNode("span", { class: "bi bi-funnel" }, null, -1),
        _createElementVNode("span", null, " No Tag Filter ", -1)
      ]), 512), [
        [_vShow, _ctx.filterTags.length === 0]
      ]),
      _withDirectives(_createElementVNode("span", null, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "bi bi-funnel-fill" }, null, -1)),
        _createElementVNode("span", null, [
          _cache[3] || (_cache[3] = _createTextVNode(" All of ")),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterTags, (tag) => {
            return _withDirectives((_openBlock(), _createElementBlock("span", {
              key: tag.name,
              title: tag.description,
              class: "badge bg-secondary ms-1"
            }, [
              _createTextVNode(_toDisplayString(tag.name), 1)
            ], 8, _hoisted_3)), [
              [_directive_bs_tooltip]
            ])
          }), 128))
        ])
      ], 512), [
        [_vShow, _ctx.filterTags.length !== 0]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_tags_selector, {
        modelValue: _ctx.filterTags,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterTags) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.filterTags)))
        ],
        class: "ms-2",
        "available-tags": _ctx.availableTags
      }, null, 8, ["modelValue", "available-tags"])
    ])
  ]))
}