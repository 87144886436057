import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  ref: "picker",
  style: {"display":"inline-block"}
}
const _hoisted_3 = { class: "btn btn-outline-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'htmlTarget' + _ctx.$.uid
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "bi bi-calendar3",
          "aria-hidden": "true"
        }, null, -1)),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 512)
  ], 8, _hoisted_1))
}