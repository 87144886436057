import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "navbar-collapse collapse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(['navbar', 'navbar-expand', 'navbar-' + _ctx.variant, 'bg-' + _ctx.variant])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: "navbar-brand",
        style: {"cursor":"pointer"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
      }, [
        _renderSlot(_ctx.$slots, "brand")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2))
}