import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  class: "progress bg-info",
  style: {"height":"2rem"}
}
const _hoisted_3 = { class: "text-black" }
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_alert = _resolveComponent("bs-alert")!
  const _component_progress_bar_slice = _resolveComponent("progress-bar-slice")!
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_ghost_editor = _resolveComponent("ghost-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Upload", -1)),
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, i) => {
        return (_openBlock(), _createBlock(_component_bs_alert, {
          key: i,
          variant: "danger"
        }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Oops! An error occurred.", -1)),
            _createTextVNode(" " + _toDisplayString(error), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ], 512), [
      [_vShow, _ctx.errors]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_progress_bar_slice, {
        percent: (_ctx.quota.current / _ctx.quota.max) * 100,
        class: _normalizeClass({
          'overflow-visible bg-success': _ctx.quotaPercent < 80,
          'overflow-visible bg-warning':
            _ctx.quotaPercent >= 80 && _ctx.quotaPercent < 90,
          'overflow-visible bg-danger': _ctx.quotaPercent >= 90,
        })
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, " Quota " + _toDisplayString(_ctx.prettyBytes(_ctx.quota.current, true)) + " of " + _toDisplayString(_ctx.prettyBytes(_ctx.quota.max, true)), 1)
        ]),
        _: 1
      }, 8, ["percent", "class"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "list-group-item border-primary bg-light",
        onDrop: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropFile && _ctx.dropFile(...args)), ["prevent"])),
        onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("a", {
          class: "text-center btn w-100",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
        }, [
          _withDirectives(_createElementVNode("span", null, "Select ghost or drop here", 512), [
            [_vShow, !_ctx.file]
          ]),
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.file?.name), 513), [
            [_vShow, _ctx.file]
          ])
        ])
      ], 32)
    ]),
    _withDirectives(_createElementVNode("input", {
      ref: "fileInput",
      type: "file",
      accept: ".tar.xz",
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args)))
    }, null, 544), [
      [_vShow, false]
    ]),
    _createElementVNode("a", {
      class: _normalizeClass(["btn w-100", { disabled: !_ctx.file, 'btn-primary': _ctx.file }]),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doUpload()))
    }, _cache[6] || (_cache[6] = [
      _createElementVNode("i", { class: "bi bi-upload" }, null, -1),
      _createTextVNode(" Upload ")
    ]), 2),
    (_ctx.stagingGhosts)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_5, "Unpublished Ghosts"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stagingGhosts, (ghost, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: ghost.id,
          class: "list-group-item"
        }, [
          _createVNode(_component_ghost_editor, {
            modelValue: _ctx.stagingGhosts[index],
            "onUpdate:modelValue": ($event: any) => ((_ctx.stagingGhosts[index]) = $event),
            onSaved: _ctx.updateGhostList
          }, {
            default: _withCtx(() => [
              _createVNode(_component_bs_btn, {
                variant: "danger",
                small: "",
                onClick: ($event: any) => (_ctx.deleteGhost(ghost.id))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("span", { class: "bi bi-trash" }, null, -1),
                  _createTextVNode(" Delete ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "onSaved"])
        ]))
      }), 128))
    ])
  ]))
}