import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_bs_tooltip = _resolveDirective("bs-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
      return _withDirectives((_openBlock(), _createElementBlock("span", {
        key: tag.name,
        title: tag.description,
        class: "badge bg-secondary me-1"
      }, [
        _createTextVNode(_toDisplayString(tag.name), 1)
      ], 8, _hoisted_1)), [
        [_directive_bs_tooltip]
      ])
    }), 128))
  ]))
}