import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row row-cols-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_select = _resolveComponent("bs-select")!
  const _component_bs_checkbox_single = _resolveComponent("bs-checkbox-single")!
  const _component_floating_single_line_input = _resolveComponent("floating-single-line-input")!
  const _component_tags_selector = _resolveComponent("tags-selector")!
  const _component_bs_btn = _resolveComponent("bs-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_bs_select, {
          modelValue: _ctx.localGhost.level_id,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localGhost.level_id) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.updated()))
          ],
          items: _ctx.alternativeLevels,
          label: "Level"
        }, null, 8, ["modelValue", "items"]),
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "bi bi-stopwatch" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.seconds(_ctx.localGhost.duration)) + " • ", 1),
        _createVNode(_component_bs_checkbox_single, {
          modelValue: _ctx.localGhost.published,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localGhost.published) = $event)),
            _cache[3] || (_cache[3] = ($event: any) => (_ctx.updated()))
          ]
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createElementVNode("span", { class: "bi bi-cloud" }, null, -1),
            _createTextVNode(" Published ")
          ])),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createVNode(_component_floating_single_line_input, {
      modelValue: _ctx.localGhost.description,
      "onUpdate:modelValue": [
        _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localGhost.description) = $event)),
        _cache[5] || (_cache[5] = ($event: any) => (_ctx.updated()))
      ],
      class: "row",
      label: "Description"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_tags_selector, {
      modelValue: _ctx.localGhost.tags,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localGhost.tags) = $event)),
      "available-tags": _ctx.tags,
      class: "row"
    }, null, 8, ["modelValue", "available-tags"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_bs_btn, {
        variant: "success",
        small: "",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveGhost()))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("span", { class: "bi bi-save" }, null, -1),
          _createTextVNode(" Save ")
        ])),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}