import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_session_editor = _resolveComponent("session-editor")!

  return (_ctx.session)
    ? (_openBlock(), _createBlock(_component_session_editor, {
        key: 0,
        modelValue: _ctx.session,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.session) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_bs_btn, {
            variant: "success",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSession()))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "bi bi-save" }, null, -1),
              _createTextVNode(" Save ")
            ])),
            _: 1
          }),
          _createVNode(_component_bs_btn, {
            variant: "danger",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("span", { class: "bi bi-x-square" }, null, -1),
              _createTextVNode(" Abort ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}