import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check form-check-inline" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.$.uid + '',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
      class: "form-check-input",
      type: "checkbox"
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.checked]
    ]),
    _createElementVNode("label", {
      for: _ctx.$.uid + ''
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ]))
}