import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-title form-check form-check-inline" }
const _hoisted_5 = { title: "This session will not be listed for others." }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_floating_single_line_input = _resolveComponent("floating-single-line-input")!
  const _component_bs_checkbox_single = _resolveComponent("bs-checkbox-single")!
  const _component_date_time_picker = _resolveComponent("date-time-picker")!
  const _component_tags_selector = _resolveComponent("tags-selector")!
  const _directive_bs_tooltip = _resolveDirective("bs-tooltip")!

  return (_ctx.tags != null && _ctx.localSession)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createVNode(_component_floating_single_line_input, {
          modelValue: _ctx.localSession.description,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localSession.description) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.sessionUpdated()))
          ],
          label: "Description",
          required: ""
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_bs_checkbox_single, {
                modelValue: _ctx.localSession.private,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localSession.private) = $event)),
                  _cache[3] || (_cache[3] = ($event: any) => (_ctx.sessionUpdated()))
                ]
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, _cache[12] || (_cache[12] = [
                    _createElementVNode("span", { class: "bi bi-eye-slash" }, null, -1),
                    _createTextVNode(" Private ")
                  ]))), [
                    [_directive_bs_tooltip]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_bs_checkbox_single, {
                modelValue: _ctx.isEvent,
                "onUpdate:modelValue": [
                  _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isEvent) = $event)),
                  _cache[5] || (_cache[5] = ($event: any) => (_ctx.sessionUpdated()))
                ]
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Scheduled Event ")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            (_ctx.localSession.time !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_date_time_picker, {
                    modelValue: _ctx.localSession.time.start,
                    "onUpdate:modelValue": [
                      _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localSession.time.start) = $event)),
                      _cache[7] || (_cache[7] = ($event: any) => (_ctx.sessionUpdated()))
                    ]
                  }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("strong", null, " Start Time ", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.datetime(_ctx.localSession.time.start)), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_date_time_picker, {
                    modelValue: _ctx.localSession.time.end,
                    "onUpdate:modelValue": [
                      _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localSession.time.end) = $event)),
                      _cache[9] || (_cache[9] = ($event: any) => (_ctx.sessionUpdated()))
                    ]
                  }, {
                    default: _withCtx(() => [
                      _cache[15] || (_cache[15] = _createElementVNode("strong", null, " End Time ", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.datetime(_ctx.localSession.time.end)), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _cache[16] || (_cache[16] = _createElementVNode("small", null, "All times are in your local time zone.", -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_tags_selector, {
          modelValue: _ctx.localSession.tags,
          "onUpdate:modelValue": [
            _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localSession.tags) = $event)),
            _cache[11] || (_cache[11] = ($event: any) => (_ctx.sessionUpdated()))
          ],
          "available-tags": _ctx.tags
        }, null, 8, ["modelValue", "available-tags"]),
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}