import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3 row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "mb-3 row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "mb-3 row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "form-group mb-3" }
const _hoisted_8 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_floating_single_line_input = _resolveComponent("floating-single-line-input")!
  const _component_bs_alert = _resolveComponent("bs-alert")!
  const _component_bs_btn = _resolveComponent("bs-btn")!

  return (_openBlock(), _createElementBlock("form", {
    class: "px-8",
    style: {"padding":"0.6666em"},
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.formSubmit()), ["prevent","stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_floating_single_line_input, {
          modelValue: _ctx.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
          type: "text",
          required: "",
          label: "Username"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_floating_single_line_input, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          required: "",
          label: "Password"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_bs_alert, { variant: "danger" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.loginError), 1)
          ]),
          _: 1
        })
      ])
    ], 512), [
      [_vShow, _ctx.loginError]
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_bs_btn, {
        class: "w-100",
        variant: "success",
        type: "submit",
        disabled: !_ctx.username || !_ctx.password
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Login ")
        ])),
        _: 1
      }, 8, ["disabled"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_bs_btn, {
        class: "w-100",
        variant: "primary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/register')))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Register ")
        ])),
        _: 1
      })
    ])
  ], 32))
}