import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table table-sm table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("caption", null, " List of " + _toDisplayString(_ctx.game) + " level names and their corresponding filenames ", 1),
    _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", {
          scope: "col",
          class: "w-auto"
        }, "Level Name"),
        _createElementVNode("th", {
          scope: "col",
          class: "w-75"
        }, "Filename")
      ])
    ], -1)),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (level) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: level[0]
        }, [
          _createElementVNode("td", null, _toDisplayString(level[1]), 1),
          _createElementVNode("td", null, [
            _createElementVNode("code", null, _toDisplayString(level[0]), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}