import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = {
  ref: "downloadAnchor",
  class: "hiding"
}
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = { class: "table table-bordered table-hover" }
const _hoisted_4 = {
  scope: "col",
  class: "fit"
}
const _hoisted_5 = {
  class: "bi bi-download",
  title: "Downloads"
}
const _hoisted_6 = { class: "fit" }
const _hoisted_7 = { class: "fit" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "row mt-1"
}
const _hoisted_10 = { class: "col col-auto" }
const _hoisted_11 = { class: "col col-auto" }
const _hoisted_12 = { class: "fit" }
const _hoisted_13 = { class: "fit" }
const _hoisted_14 = { class: "fit" }
const _hoisted_15 = { class: "fit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_filter_selector = _resolveComponent("tag-filter-selector")!
  const _component_bs_select = _resolveComponent("bs-select")!
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_tag_list = _resolveComponent("tag-list")!
  const _directive_bs_tooltip = _resolveDirective("bs-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", _hoisted_1, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tag_filter_selector, {
        modelValue: _ctx.filterTags,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterTags) = $event)),
        "available-tags": _ctx.tags
      }, null, 8, ["modelValue", "available-tags"]),
      _createVNode(_component_bs_select, {
        modelValue: _ctx.levelFilter,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.levelFilter) = $event)),
        items: _ctx.levels,
        label: "Level Filter",
        class: "ms-1"
      }, null, 8, ["modelValue", "items"]),
      _createVNode(_component_bs_select, {
        modelValue: _ctx.finishTypeFilter,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.finishTypeFilter) = $event)),
        items: _ctx.finishTypes,
        label: "Finish Type Filter",
        class: "ms-1"
      }, null, 8, ["modelValue", "items"]),
      _createVNode(_component_bs_select, {
        modelValue: _ctx.ordering,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ordering) = $event)),
        items: _ctx.orderingItems,
        label: "Ordering",
        class: "ms-1"
      }, null, 8, ["modelValue", "items"])
    ]),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_4, [
            _withDirectives(_createElementVNode("span", _hoisted_5, null, 512), [
              [_directive_bs_tooltip]
            ])
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("th", {
            scope: "col",
            class: "fit"
          }, "Level", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("th", {
            scope: "col",
            class: "fit"
          }, "Duration", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("th", {
            scope: "col",
            class: "fit"
          }, "Tags", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("th", {
            scope: "col",
            class: "fit"
          }, "Finish Type", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("th", {
            scope: "col",
            class: "fit"
          }, "Uploader", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("th", { scope: "col" }, "Description", -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredGhosts, (ghost) => {
          return _withDirectives((_openBlock(), _createElementBlock("tr", {
            key: ghost.id
          }, [
            _createElementVNode("td", _hoisted_6, _toDisplayString(ghost.downloads), 1),
            _createElementVNode("td", _hoisted_7, [
              _createElementVNode("a", {
                href: "",
                onClick: _withModifiers(($event: any) => (_ctx.downloadGhost(ghost.id)), ["prevent"])
              }, _toDisplayString(ghost.level_display), 9, _hoisted_8),
              (
                _ctx.profile.$state.username === ghost.username ||
                _ctx.profile.$state.is_staff
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_bs_btn, {
                        variant: "danger",
                        small: "",
                        onClick: ($event: any) => (_ctx.deleteGhost(ghost.id))
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("i", { class: "bi bi-trash" }, null, -1),
                          _createTextVNode(" Delete ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_bs_btn, {
                        variant: "primary",
                        small: "",
                        onClick: ($event: any) => (_ctx.$router.push('/edit-ghost/' + ghost.id))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("i", { class: "bi bi-pencil" }, null, -1),
                          _createTextVNode(" Edit ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.seconds(ghost.duration)), 1),
            _createElementVNode("td", _hoisted_13, [
              _createVNode(_component_tag_list, {
                tags: ghost.tags
              }, null, 8, ["tags"])
            ]),
            _createElementVNode("td", _hoisted_14, _toDisplayString(ghost.finish_type), 1),
            _createElementVNode("td", _hoisted_15, _toDisplayString(ghost.username), 1),
            _createElementVNode("td", null, _toDisplayString(ghost.description), 1)
          ])), [
            [_vShow, 
            (_ctx.levelFilter === null || _ctx.levelFilter === ghost.level_id) &&
            (_ctx.finishTypeFilter === null ||
              _ctx.finishTypeFilter === ghost.finish_type)
          ]
          ])
        }), 128))
      ])
    ])
  ]))
}