import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "input-group mb-3 disabled" }
const _hoisted_3 = { class: "input-group mb-3 disabled" }
const _hoisted_4 = { class: "input-group mb-3 disabled" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_alert = _resolveComponent("bs-alert")!
  const _component_bs_btn = _resolveComponent("bs-btn")!

  return (!_ctx.registrationSuccessful)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Register", -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "input-group-text" }, "Username", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.registrationInfo.username) = $event)),
            type: "text",
            class: "form-control",
            placeholder: "Username",
            "aria-label": "Username",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.registrationInfo.username]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "input-group-text" }, "Email", -1)),
          _withDirectives(_createElementVNode("input", {
            ref: "email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registrationInfo.email) = $event)),
            type: "email",
            class: "form-control",
            placeholder: "Email",
            "aria-label": "Email",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.registrationInfo.email]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "input-group-text" }, "Password", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.registrationInfo.password) = $event)),
            type: "password",
            class: "form-control",
            placeholder: "Password",
            "aria-label": "Password",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.registrationInfo.password]
          ])
        ]),
        _withDirectives(_createVNode(_component_bs_alert, { variant: "danger" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.error]
        ]),
        _createVNode(_component_bs_btn, {
          variant: "primary",
          disabled: 
        !_ctx.registrationInfo.password ||
        !_ctx.registrationInfo.email ||
        !_ctx.$refs.email.validity.valid ||
        !_ctx.registrationInfo.username
      ,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.register()))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Register ")
          ])),
          _: 1
        }, 8, ["disabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Registration Successful", -1)),
        _createVNode(_component_bs_alert, { variant: "primary" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createElementVNode("p", null, "Successfully registered", -1),
            _createElementVNode("p", null, "Check your email to activate your account", -1)
          ])),
          _: 1
        })
      ]))
}