import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label", "disabled"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    class: "form-select",
    "aria-label": _ctx.label,
    disabled: _ctx.disabled,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatedSelection()))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("option", {
        key: item,
        value: item.value
      }, _toDisplayString(item.title), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1)), [
    [_vModelSelect, _ctx.selectedValue]
  ])
}