import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "modal",
    class: "modal fade",
    tabindex: "-1",
    "aria-labelledby": _ctx.$.uid + '--title',
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", {
            id: _ctx.$.uid + '--title',
            class: "modal-title"
          }, _toDisplayString(_ctx.title), 9, _hoisted_5),
          _cache[0] || (_cache[0] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "modal-footer" }, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            "data-bs-dismiss": "modal"
          }, " Close ")
        ], -1))
      ])
    ])
  ], 8, _hoisted_1))
}