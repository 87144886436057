import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  style: {"display":"block"},
  class: "bg-dark"
}
const _hoisted_3 = { class: "navbar-text text-info" }
const _hoisted_4 = { style: {"padding":"0.6666em"} }
const _hoisted_5 = { class: "container-fluid container-md pb-5 mb-5 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_modal = _resolveComponent("bs-modal")!
  const _component_bs_router_nav_item = _resolveComponent("bs-router-nav-item")!
  const _component_bs_navbar_nav = _resolveComponent("bs-navbar-nav")!
  const _component_clipboard_copyable = _resolveComponent("clipboard-copyable")!
  const _component_bs_dropdown_nav_item = _resolveComponent("bs-dropdown-nav-item")!
  const _component_logout_widget = _resolveComponent("logout-widget")!
  const _component_login_widget = _resolveComponent("login-widget")!
  const _component_bs_navbar = _resolveComponent("bs-navbar")!
  const _component_site_announcements_list = _resolveComponent("site-announcements-list")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bs_modal, {
      ref: "errorModal",
      title: "Oooops"
    }, {
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createTextVNode(" Sorry, an error occured. Please come back again later. ")),
        _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
        _cache[6] || (_cache[6] = _createTextVNode(" This is some warp drive internal error message, possibly useful to whoever messed this up: ")),
        _withDirectives(_createElementVNode("code", {
          style: {"display":"block"},
          class: "bg-dark mb-3"
        }, _toDisplayString(_ctx.info), 513), [
          [_vShow, _ctx.info]
        ]),
        _createElementVNode("code", _hoisted_2, [
          _createTextVNode(" Name: " + _toDisplayString(_ctx.err?.name), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" Message: " + _toDisplayString(_ctx.err?.message), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _cache[2] || (_cache[2] = _createTextVNode(" Stack:")),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.err?.stack), 1)
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_bs_navbar, { variant: "dark" }, {
      brand: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("img", {
          src: "logo.png",
          alt: "Logo",
          style: {"height":"20px"}
        }, null, -1),
        _createTextVNode(" Haunted Server ")
      ])),
      default: _withCtx(() => [
        _createVNode(_component_bs_navbar_nav, null, {
          default: _withCtx(() => [
            _createVNode(_component_bs_router_nav_item, { to: "/" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("span", { class: "bi bi-list-ul" }, null, -1),
                _createTextVNode(" Sessions ")
              ])),
              _: 1
            }),
            (_ctx.profileInfo.authenticated)
              ? (_openBlock(), _createBlock(_component_bs_router_nav_item, {
                  key: 0,
                  to: "/create-session"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("span", { class: "bi bi-plus-square-fill" }, null, -1),
                    _createTextVNode(" Create Session ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_bs_router_nav_item, { to: "/ghosts" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("span", { class: "bi bi-list-ul" }, null, -1),
                _createTextVNode(" Ghosts ")
              ])),
              _: 1
            }),
            (_ctx.profileInfo.authenticated)
              ? (_openBlock(), _createBlock(_component_bs_router_nav_item, {
                  key: 1,
                  to: "/upload-ghosts"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("span", { class: "bi bi-upload" }, null, -1),
                    _createTextVNode(" Upload Ghosts ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_bs_router_nav_item, { to: "/about" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createElementVNode("span", { class: "bi bi-question-circle" }, null, -1),
                _createTextVNode(" About ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bs_navbar_nav, { ms: "auto" }, {
          default: _withCtx(() => [
            _createElementVNode("li", _hoisted_3, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "bi bi-bar-chart" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.serverInfo.total_users) + " users, " + _toDisplayString(_ctx.serverInfo.total_sessions) + " sessions, " + _toDisplayString(_ctx.serverInfo.total_ghosts) + " ghosts, " + _toDisplayString(_ctx.seconds(_ctx.serverInfo.total_ghost_duration)) + " total ghost time ", 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_bs_navbar_nav, { ms: "auto" }, {
          default: _withCtx(() => [
            (_ctx.profileInfo.authenticated)
              ? (_openBlock(), _createBlock(_component_bs_dropdown_nav_item, {
                  key: 0,
                  "min-width": "20em",
                  end: ""
                }, {
                  toggle: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createElementVNode("span", { class: "bi bi-key-fill" }, null, -1),
                    _createTextVNode(" Session Credentials ")
                  ])),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, [
                        _cache[15] || (_cache[15] = _createTextVNode(" The Server Address is ")),
                        _createVNode(_component_clipboard_copyable, {
                          value: _ctx.serverInfo.coop_url
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("code", null, _toDisplayString(_ctx.serverInfo.coop_url), 1)
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1)),
                      _createElementVNode("div", null, [
                        _cache[16] || (_cache[16] = _createTextVNode(" Your Username is ")),
                        _createVNode(_component_clipboard_copyable, {
                          value: _ctx.profileInfo.username
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("code", null, _toDisplayString(_ctx.profileInfo.username), 1)
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _cache[20] || (_cache[20] = _createElementVNode("hr", null, null, -1)),
                      _createElementVNode("div", null, [
                        _cache[17] || (_cache[17] = _createTextVNode(" Your Personal Auth Token is ")),
                        _createVNode(_component_clipboard_copyable, {
                          value: _ctx.profileInfo.auth_token
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("code", null, _toDisplayString(_ctx.profileInfo.auth_token), 1)
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _cache[18] || (_cache[18] = _createElementVNode("small", { class: "text-secondary" }, " You can re-generate this token in your profile if it gets compromised. ", -1))
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.profileInfo.authenticated)
              ? (_openBlock(), _createBlock(_component_bs_dropdown_nav_item, {
                  key: 1,
                  end: ""
                }, {
                  toggle: _withCtx(() => [
                    _cache[21] || (_cache[21] = _createElementVNode("span", { class: "bi bi-person-fill" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.profileInfo.username), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_logout_widget)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_bs_dropdown_nav_item, {
                  key: 2,
                  end: ""
                }, {
                  toggle: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createElementVNode("span", { class: "bi bi-box-arrow-in-right" }, null, -1),
                    _createTextVNode(" Login/Register ")
                  ])),
                  default: _withCtx(() => [
                    _createVNode(_component_login_widget)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("article", _hoisted_5, [
      _createVNode(_component_site_announcements_list),
      _createVNode(_component_router_view, { class: "mt-1" })
    ]),
    _cache[23] || (_cache[23] = _createElementVNode("footer", { class: "bg-dark text-light fixed-bottom" }, [
      _createElementVNode("div", {
        class: "text-center",
        style: {"margin":"1em"}
      }, " This site only uses strictly necessary cookies. No ad cookies, no analytics cookies, no third-party cookies. ")
    ], -1))
  ]))
}